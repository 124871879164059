.logoutButton {
  background-color: transparent;
  border: 1px solid transparent;
}

.logoutButton:hover {
  border: 1px solid white;
}

.chooseRealmsLink {
  background-color: transparent;
  border: 1px solid transparent;
}

.chooseRealmsLink:hover {
  border: 1px solid white;
}
