:global(.leaflet-div-icon) {
  background: none;
  border: none;
}
.marker {
  position: absolute;
  transform: rotate(-45deg);
  height: 30px;
  width: 30px;
}
.marker.outlined .pointer {
  border: 1px solid black;
  border-top: 0;
  border-right: 0;
}
.marker.outlined .shape {
  border: 1px solid black;
}

.pointer {
  position: absolute;
  left: 15px;
  height: 15px;
  width: 15px;
  border-radius: 0%;
}
.shape {
  position: absolute;
  top: -15px;
  left: 15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.shadow {
  position: absolute;
  top: 0px;
  left: 8px;
  height: 30px;
  width: 15px;
  background-color: var(--black);
  border-radius: 50%;
  opacity: 0.25;
  transform: rotate(135deg);
}

.background {
  position: absolute;
  top: -12px;
  left: 18px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.icon {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 20px;
  transform: rotate(45deg);
}

.vehicleMarker {
  font-size: 1.77em;
  color: lime;
  -webkit-text-stroke: 1px #000;
}

.stopLocationTextWrap {
  transform: rotate(44deg);
  padding-left: 6px;
  font-size: 17px;
}

.iconDoubleChar {
  position: absolute;
  top: -3px;
  left: 1px;
  font-size: 20px;
  transform: rotate(45deg);
}

.counter {
  color: #fff;
  display: flex;
  margin-right: 2px;
  width: 100%;
}