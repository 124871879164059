.stopMarker {
  font-size: 2.6em;
  color: #3388ff !important;
  -webkit-text-stroke: 1px #000;
}

.stopMarker.cancelled {
  color: red !important;
}

.stopMarker.highlighted {
  color: gold !important;
}

.settingsControls {
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.settingsControls h5 {
  font-size: 1.3em;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  height: 34px;
  line-height: 34px;
}

.settingsControls form {
  padding: 0 7px 5px 7px;
}

#highlights-switch {
  line-height: 24px;
}
