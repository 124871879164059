.wrapper {
  overflow-x: hidden;
}

:global(.brand-image).brandLogo {
  background: white;
  padding: 8px;
  border-radius: 50%;
  max-height: initial;
  color: #343a40;
}
