
.loginForm {
  display: flex;
  flex-direction: column;
}

.loginForm input {
  box-shadow: none;
  border: 1px solid #ccc;
  padding: 6px 12px;
  margin: .4rem 0;
}

.loginForm input.invalid {
  border: 1px solid red;
}

.loginForm input.invalid + span {
  color: red;
  font-size: 12px;
}

.loginForm button {
  margin-top: 0.25rem;
  margin-left: auto;
  font-size: 14px;
  border-radius: 0;
  padding: 6px 15px;
}

.loginForm button span {
  margin-right: 7px;
}