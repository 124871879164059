.wrapper {
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  max-height: 500px;
  padding: 10px 20px 0 20px;
}
.footer{
  border-top: 1px solid rgba(0,0,0,.125);
}

.footer a {
  color: white !important;
}

.noItemsInfo {
  padding: 6px 20px;
}
