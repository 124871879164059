:root {
  --main-color: #239fdd;
  --main-color-darker: #1e76a1;
  --main-color-lighter: #e5f2f9;
  --black: #000;
  --white: #fff;
  --passenger-not-present: #c10101;
}

html {
  height: 100vh;
}

body,
#root {
  color: #343a40;
  height: 100%;
}

a {
  color: var(--main-color);
}

.nav-link {
  cursor: pointer;
}

.wrapper .content-wrapper {
  height: calc(100vh - 57px);
  overflow-y: auto;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

input[type="range"] {
  -webkit-appearance: none;
  height: 5px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: opacity 0.15s ease-in-out;
  transition: opacity 0.15s ease-in-out;
}

input[type="range"]:hover {
  opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--blue);
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--blue);
  cursor: pointer;
}

.content-header {
  padding-left: 0.8rem;
}

.content-wrapper {
  overflow-x: hidden;
}

.content-wrapper > .content {
  padding-left: 0.8rem;
}

.pagination {
  margin-bottom: 0;
}

.navbar-info {
  background-color: var(--main-color);
}

.sidebar-dark-info .nav-sidebar > .nav-item > .nav-link.active {
  background-color: var(--main-color);
}

.btn.btn-main {
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.btn.btn-main:hover {
  background-color: var(--main-color-darker);
  border-color: var(--main-color-darker);
}

.btn[disabled] {
  background: var(--black);
  border-color: var(--white);
  opacity: 0.2;
}

div.tooltip {
  z-index: 1033;
}

.card .nav-item .active {
  font-weight: bold;
}

.card .nav-item a {
  padding: 8px 10px 0 10px;
  color: rgb(33, 37, 41);
}

.dropdown-actions .dropdown-toggle::after {
  display: none;
}

.dropdown-with-long-texts .dropdown-menu {
  min-width: 14rem;
}

.map-bonding-box {
  border: 2px solid var(--main-color);
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker-popper {
  z-index: 401;
}

/* ==========================================================================
   Typography
   ========================================================================== */
.font-larger {
  font-size: larger;
}

/* ==========================================================================
   Utility
   ========================================================================== */

.cursor-pointer {
  cursor: pointer;
}

/* ==========================================================================
   Colors
   ========================================================================== */

.text-main {
  color: var(--main-color);
}

.text-black {
  color: var(--black);
}

.text-white {
  color: var(--white);
}

.bg-main {
  background-color: var(--main-color);
}

.bg-black {
  background-color: var(--black);
}

.bg-white {
  background-color: var(--white);
}
