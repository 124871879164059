.paginationRow {
  line-height: 31px;
  padding: 0 .75rem;
}

.jumpToPage {
  min-width: 161px;
}

.jumpToPage input {
  width: 3.2rem;
  margin: 0 5px;
}

.pageSizeSelect select {
  width: 3.4rem;
}

.loadingSpinner {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 100%;
  height: 100%;
}

.loadingSpinner > div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}