.notificationContainer {
  z-index: 1090;
  max-width: 60%;
  width: fit-content;
}

.notificationContainer.topRight {
  top: 65px;
  right: 35px;
}

.notificationContainer.topCenter {
  top: 65px;
  right: 0;
  left: 0;
  margin: auto;
}

.notificationEnter {
  opacity: 0.01;
}

.notificationEnter.notificationEnterActive {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.notificationExit {
  opacity: 1;
}

.notificationExit.notificationExitActive {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.notificationContainer :global(.alert) {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.notificationContainer :global(.alert-heading) {
  color: inherit;
}
.notificationContainer :global(.alert-link) {
  font-weight: 700;
}
.notificationContainer :global(.alert-dismissible) {
  padding-right: 3rem;
}
.notificationContainer :global(.alert-dismissible .close) {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.notificationContainer :global(.alert-primary) {
  color: #004085 !important;
  background-color: #cce5ff !important;
  border-color: #b8daff;
}
.notificationContainer :global(.alert-secondary) {
  color: #383d41 !important;
  background-color: #e2e3e5 !important;
  border-color: #d6d8db;
}
.notificationContainer :global(.alert-success) {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb;
}
.notificationContainer :global(.alert-info) {
  color: #0c5460 !important;
  background-color: #d1ecf1 !important;
  border-color: #bee5eb;
}
.notificationContainer :global(.alert-warning) {
  color: #856404 !important;
  background-color: #fff3cd !important;
  border-color: #ffeeba;
}
.notificationContainer :global(.alert-danger) {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb;
}
.notificationContainer :global(.alert-light) {
  color: #818182 !important;
  background-color: #fefefe !important;
  border-color: #fdfdfe;
}
.notificationContainer :global(.alert-dark) {
  color: #1b1e21 !important;
  background-color: #d6d8d9 !important;
  border-color: #c6c8ca;
}
