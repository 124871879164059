@keyframes highlightAdd {
  0% {
    color: inherit;
    background: none;
  }
  50% {
    color: #fff;
    background: #28a745;
  }
  to {
    color: inherit;
    background: none;
  }
}

.itemEnterActive {
  animation: highlightAdd 1s linear;
  animation-iteration-count: 2;
}

@keyframes highlightRemove {
  0% {
    color: inherit;
    background: none;
  }
  50% {
    color: #fff;
    background: #dc3545;
  }
  to {
    color: inherit;
    background: none;
  }
}

.itemExit {
  opacity: 1;
}

.itemExitActive {
  opacity: 0;
  transition: opacity 2s linear;
  animation: highlightRemove 1s linear;
  animation-iteration-count: 2;
}

@keyframes highlightUpdate {
  0% {
    background: none;
  }
  50% {
    background: #fed44a;
  }
  to {
    background: none;
  }
}

.itemUpdateActive {
  animation: highlightUpdate 1s linear;
  animation-iteration-count: 2;
}
