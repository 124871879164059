@import url('https://fonts.googleapis.com/css?family=Krona+One&display=swap');

.centeredPageStyle {
  height: 100%;
  display: flex;
  justify-content: center;
  background: var(--main-color);
  overflow: auto;
}

.centeredBoxWrapper h1 {
  font-family: 'Krona One', Sans-Serif;
  font-size: 36px;
  color: #FFF;
  font-weight: bold;
  margin: 15vh 0 0.75rem 0;
  min-width: 327px;
  text-align: center;
}

.contentBox {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 9px;
  background: #FFF;
  padding: 20px 20px;
  font-size: 14px;
  margin-bottom: 5rem;
}