.sliderWrapper {
  display: flex;
  align-items: center;
}

.sliderWrapper input {
  width: 100%;
}

.valueDisplay {
  display: inline-block;
  width: 3.2rem;
}

.label {
  min-width: 10rem;
  display: inline-block;
}

.errorBox {
  font-size: 80%;
}

.invalid input[type=range]::-webkit-slider-thumb {
  background: var(--red);
}