.loadingOverlay {
  display: none;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.287);
  position: absolute;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}

.loadingOverlay.visible {
  display: flex;
}