.contentWrapper {
    display: flex;
    flex-direction: column;
}

.content {
    flex-grow: 1;
    position: relative;
}

.segmentInfo {
    margin: 0;
    padding: 0;
}

.horizontalSlider {
    height: 50px;
    border: 0;
}

.horizontalMark {
    bottom: calc(50% - 20px);
    background-color: red;
    position: absolute;
    width: 1px;
    height: 40px;
    font-weight: bold;
}

.shortMark {
    height: 0px;
    bottom: calc(50% - 10px);
    font-weight: normal;
}

.horizontalTrack {
    top: 25px;
    height: 1px;
    background-color: black;
    position: absolute;
}

.horizontalThumb {
    position: absolute;
    text-align: center;
    background-color: black;
    color: white;
    cursor: pointer;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-left: -9px;
    margin-top: 14px;
}

.markDesc {
    transform: rotate(-60deg);
    margin-top: -50px;
    margin-left: -20px;
    width: 80px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; 
    font-size: 12px;
}

.slider {
    padding: 20px; 
    padding-left: 20px; 
    padding-right: 60px;
    position: relative;
    border-radius:5px;
    background-color:rgba(255,255,255,0.8);
    margin: auto;
    padding-top: 80px;
    overflow-x: overlay;
}