.invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.info {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #0087e0;
}
