.filterBox {
  max-width: 200px;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 999;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,.125);
}

.filterBox h5 {
  font-size: 1.1rem;
}