.scaleWrapper {
  height: 10px;
}

.scaleStep {
  height: 10px;
  border: 1px solid grey;
  border-bottom: none;
  position: relative;
}

.scaleLabel {
  position: absolute; 
  top: -24px;
}

.scaleLabel.left {
  left: -20px; 
}

.scaleLabel.right {
  right: -20px; 
}

.patternTimeSlot:hover {
  background-color: #ffc107;
}

.patternInputRow {
  margin-top: auto;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.patternIdentifier {
  position: absolute;
  z-index: 1;
  top: -0.65em;
  background: white;
  left: 0.4em;
  padding: 0 0.4em 0 0.3em;
}

.patternTimes {
  text-align: center;
}

.patternTimes th {
  min-width: 75px;
}