:global .leaflet-div-icon {
  background: transparent;
  border: none;
}

.regularStop {
  height: 13px;
  width: 13px;
  background-color: white;
  border: 1px solid black;
  border-radius: 50%;
}

.wrapper .counter {
  padding-left: 1px;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  position: absolute;
  background-color: white;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  text-align: center;
  width: 15px;
  height: 15px;
  top: -6px;
  left: -8px;
}

.markerPopup :global(.leaflet-popup-content-wrapper .leaflet-popup-content) {
  margin: 20px 0 15px 0;
}

.markerPopup :global(.leaflet-popup-content-wrapper .leaflet-popup-content) ul {
  list-style-type: none;
  padding: 0;
}

.markerPopup :global(.leaflet-popup-content-wrapper .leaflet-popup-content) ul li:last-of-type {
  border-bottom: none;
}

.markerPopup :global(.leaflet-popup-content-wrapper .leaflet-popup-content) ul li {
  cursor: pointer;
  padding: 2px 5px 0 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.markerPopup :global(.leaflet-popup-content-wrapper .leaflet-popup-content) ul li:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
