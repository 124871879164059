h1.chooseRealm {
    color: var(--main-color);
    font-weight: 700;
    font-size: 1.5rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

h2.chooseRealmDesc {
    font-size: 1rem;
}

.select {
    box-sizing: content-box;
    padding: 5px;
}

.confirm {
    border-radius: 25px;
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cardBodyLogin {
    padding-top: 24px;
}

.btnConfirm {
    text-transform: uppercase;
}

.chooseRealmsLink {
    border: 1px solid rgb(201, 180, 180);
}