.tree {
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.tree:last-of-type {
  margin-right: 0;
}

.tree > ul {
  padding: 0;
}

.tree li {
  margin: 0px 0;
  list-style-type: none;
  position: relative;
  padding: 10px 5px 0 0;
}

.tree li::before {
  content: "";
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  right: auto;
  left: -20px;
  border-left: 1px solid #ccc;
}
.tree li::after {
  content: "";
  position: absolute;
  top: 24px;
  width: 20px;
  height: 20px;
  right: auto;
  left: -20px;
  border-top: 1px solid #ccc;
}
.tree li span,
.tree li div {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

/*Remove connectors before root*/
.tree > ul > li::before,
.tree > ul > li::after {
  border: 0;
}
/*Remove connectors after last child*/
.tree li:last-child::before {
  height: 24px;
}

.tree li span.passengersToggle {
  border: none;
  cursor: pointer;
  text-align: right;
  padding-right: 7px;
  float: right;
}

.tree li span.stopToggle {
  cursor: pointer;
}
