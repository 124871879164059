.mainBox {
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.2);
  width: 420px;
  font-size: 13px;
}

.mainBox h5 {
  width: 100%;
  margin: 0;
  font-size: 1.3em;
  text-align: center;
  cursor: pointer;
  line-height: 34px;
  height: 34px;
}

.mainBox h5 i:global(.fas) {
  margin-left: 7px;
  font-size: 0.9em;
}

.mainBox ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.listItem {
  display: flex;
  cursor: default;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  height: 40px;
  line-height: 40px;
}

.listItem.current div.rowInfo::before {
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  font-size: 1.33333em;
  color: #00bf00;
  content: "\f5b6";
  margin-right: 5px;
}

.listItem.completed {
  background-color: #d8d8d8;
}

.listItem.cancelled {
  background-color: #ff9999;
}

.listItem.highlighted {
  background-color: #ffe556;
}

.listItem.expanded {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.togglable .rowInfo {
  cursor: pointer;
}

.rowInfo {
  padding-left: 7px;
  flex-grow: 1;
  display: flex;
}

.rowInfo i {
  line-height: 40px;
}

.rowLabel {
  flex-grow: 1;
  width: 0;
}

.rowLabel p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.locateButton {
  padding: 0 7px;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.locateButton i {
  cursor: pointer;
}

.rowToggler {
  padding: 0 7px;
}

.passengersInfo {
  flex: 0 0 auto;
}

.passengersInfo i {
  margin: 2px;
  margin-top: -3px;
}

.passengersRow {
  padding: 4px 7px 4px 10px;
  display: flex;
  justify-content: space-between;
}

.passengersRow.notCollected {
  background-color: #ffe2b7;
}

.textOrange {
  color: #ec9f2d;
}
