.container {
  position: relative;
  z-index: 99;
}

.suggestionsContainer {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestion {
  cursor: pointer;
  padding: 5px 5px;
  border-bottom: 1px solid #d4d4d4;
}

.suggestionHighlighted {
  background-color: #e9e9e9;
}
