.missionInfo {
    position: absolute;
    width: 255px;
    min-width: 250px;
    padding: 20px;
    background-color: rgba(255,255,255,0.9);
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.1);
    z-index: 999;
    left: 0.8rem;
    top: 0.8rem;
}

.fontSizeLarger {
    font-size: larger;
}