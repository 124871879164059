.vehicle {
    position: absolute;
    width: 20%;
    text-align: center;
    padding: 10px;
    background-color: rgba(255,255,255,0.9);
    font-size: 25px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.1);
    z-index: 999;
    left: 40%;
    top: 0.8rem;
}