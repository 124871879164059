.listOfActiveSegments {
    position: absolute;
    width: auto;
    font-size: 15px;
    z-index: 999;
    right: 0.8rem;
    top: 0.8rem;
}

.listOfActiveSegments > li:not(:last-child) {
    margin-bottom: 5px;
}

.activeSegment {
    padding: 10px;
    background-color: rgba(255,255,255,0.9); 
    position: relative;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.1);
    min-width: 300px;
    opacity: 0.4;
}

.mainSegment {
    font-weight: bold;
    font-size: larger;
    background-color: rgba(255,255,255,0.9); 
    opacity: 1;
    padding-left: 10px;
    padding-right: 10px;
    border: 2px solid rgba(0,0,0,0.1);
}

.segmentStatus {
    font-size: small;
    font-weight: normal;
}

.users {
    font-size: medium;
}

.users > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.users > ul > li {
    padding: 5px;
    margin: 0;
    font-weight: normal;
}

.userPickedUp {
    color: green;
}

.userDroppedOff {
    color: red;
}

.segmentSection {
    display: flex;
}

.segmentIdCopy {
    flex-shrink: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.segmentAddress {
    flex-grow: 1;
}