.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px 0 3px;
  flex-grow: 1;
}

.removeButton {
  margin-top: 5px;
  margin-right: 4px;
  cursor: pointer;
}