.rideRequestModal :global(.modal-dialog) {
  max-width: 66%;
}

.map {
  z-index: 1;
}

.stopInputLabel {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 1px solid black;
  line-height: 20px;
}

.successModal :global(.modal-content) {
  border: 3px solid var(--success);
}

.errorNotification {
  max-width: 500px;
}

.timeCellList {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.sourceIcon {
  line-height: 1.6;
}

.username {
  width: fit-content;
}